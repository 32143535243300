export default function useCategoryI18nReverse(i18nKeyPrefix: string) {
  const { t } = useI18n()
  function findByNormalizedTranslation(options: string[], search: string) {
    const normalizedSearch = normalize(search)
    return options.map(option => [normalize(t(i18nKeyPrefix + option)), option]).find(([translation]) => translation === normalizedSearch)?.[1]
  }
  function translateNormalized(option: string, locale?: string) {
    return normalize(translate(option, locale))
  }
  function translate(option: string, locale?: string) {
    return locale ? t(i18nKeyPrefix + option, {}, { locale }) : t(i18nKeyPrefix + option)
  }
  function normalize(text: string) {
    return text.toLowerCase().replace(/[\s/&.,+-]+/g, '-')
  }
  return {
    findByNormalizedTranslation,
    translateNormalized,
    translate,
  }
}
